import React, { useState } from 'react';
import './Main.css';
import { useMediaQuery } from 'react-responsive';
import { FaWhatsapp, FaTelegram, FaEnvelope } from 'react-icons/fa';

export default function Main() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [phoneNumber, setPhoneNumber] = useState('87751949957'); // Replace with your phone number

  const copyPhoneNumber = () => {
    navigator.clipboard.writeText(phoneNumber)
      .then(() => {
        console.log('Номер скопирован:', phoneNumber);
        alert('Номер скопирован: ' + phoneNumber);
      })
      .catch(err => {
        console.error('Failed to copy phone number: ', err);
      });
  };

  const iconSize = 40;

  return (
    <div className='main'>
      <header>
        <img className='logo' src='zhako-logo.svg' width={"40px"} height={"40px"} />
        <span className="logo-text" translate="no">ZHAKO</span>

        <span className='contact'>
          <a className='padding-right-5px' href="https://api.whatsapp.com/send?phone=87783361779">
            <FaWhatsapp size={iconSize} color="black" />
          </a>
          <a className='padding-right-5px' href="https://t.me/Already_Exist_password">
            <FaTelegram size={iconSize} color="black" />
          </a>
          <a className='padding-right-last' href="mailto:xtentaclexyz@gmail.com">
            <FaEnvelope size={iconSize} color="black" />
          </a>
          <div className='number' onClick={copyPhoneNumber} style={{ cursor: 'pointer' }}>{phoneNumber}</div>
        </span>
        {/* <nav>
          <ul>
            <li><a href="#home">Главная</a></li>
            <li><a href="#services">Услуги</a></li>
            <li><a href="#about">О нас</a></li>
            <li><a href="#contact">Контакты</a></li>
          </ul>
        </nav> */}
      </header>

      <section id="home" className="hero">
        <h1>Ваш партнер в создании веб-сайтов</h1>
        <p>Мы создаем сайты, которые помогут вашему бизнесу расти.</p>
        <button>Узнать больше</button>
      </section>

      <section id="services" className="services">
        <h2>Наши услуги</h2>
        <div className="service-list">
          <div className="service-item">
            <h3>Разработка сайтов</h3>
            <p>Создаем адаптивные и современные веб-сайты для вашего бизнеса.</p>
          </div>
          <div className="service-item">
            <h3>Поддержка и обновление</h3>
            <p>Обеспечиваем техническую поддержку и обновление вашего сайта.</p>
          </div>

          <div className="service-item">
            <h3>SEO-оптимизация</h3>
            <p>Оптимизируем ваш сайт для поисковых систем.</p>
          </div>
        </div>
      </section>

    <section id="about" className="about">
      <h2>О нас</h2>
      <p>Мы — команда профессионалов с опытом в веб-разработке. Нашей задачей является создание качественных сайтов для наших клиентов.</p>
    </section>

      <h1 class="mint" translate="no">ZHAKO</h1>
      <div>
        Разрабатываю сайты любой сложности, опыт в разработке 5 лет. Цена разработки зависит от объема работ. Использую в основном новые технологии.
      </div>
      <div>
        <ul>
          <li>Разработка сайта</li>
          <li>Лэндинг странницы</li>
          <li>Специфические разработки сайтов</li>
          <li>Создание телеграм бота</li>
          <li>Создание онлайн магазина</li>
          <li>Сайты под бизнес</li>
          <li>Разработка сайтов любой сложности</li>
        </ul>
      </div>
      <div>
        {/* <h2>Работы:</h2> */}
        <ul>
          {/* <li><a href='https://w-zh.zhako.kz'>Визитка на свадьбу</a></li> */}
        </ul>
      </div>
      <footer>
      <p>© 2024 zhako.kz. Все права защищены.</p>
    </footer>
    </div>
  );
}
